:root {
  --pink: #C49090
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  /* max-width: 1920px; */
  margin :auto !important;
}

.container {
  padding:12px 12px;
}

.contents {
  max-width: 1920px;
  margin: auto;
}

.navigation {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  position: sticky !important;
  top:0;
  z-index: 1;
}

.Navbar {
  background-color: white !important;
  color:black !important;  
}

#brand {
  text-shadow: 3px 2px 1px #fff, 4px 6px 3px rgba(0,0,0,0.15);
  color: var(--pink);
  text-align:center;
  font-size:18px;
  padding-left:15px;
}

#responsive-navbar-nav {
  justify-content: flex-end;
  /* width:100vw */
}

.links {
  color:black !important;
  text-decoration: none;
  margin: 0 25px;
  font-size: 14px;
}

.links:hover {
  color:var(--pink) !important
}

.navbar-toggler {
  border-radius: 0;
  border: 1px solid white;
  align-items: flex-end;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,1.000)' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.home-section-one {
  background-color: var(--pink);
  width: 100%;
  height: 5%;
  padding:0;
}

.home-section-one h3 {
    width:100%;
    color: white;
    font-weight:bold;
    font-size:40px;
    text-align: left;
    padding:50px 50px;
    
  }

.home-section-one div {
    display: flex;
    justify-content: space-between;
  }

.home-section-one div span {
      /* border-right:1px solid white; */
      margin-bottom:5%;
      width: 50%;
    }

.home-section-one div span ul {
        text-align: left;
        color:white;
        margin : 5% 5%;
        padding: 10px 10px 10px 10px;
        list-style: none; 
      }

.home-section-one div span ul li {
          font-weight:normal !important;
        }

.home-section-one div h4 {
      color: white;
      font-weight: lighter;
      display:flex;
      justify-content: center !important;
      align-items: center !important;
      height:100%;
      margin:auto;
      width: 80%
     }

#home-section-one-right {
  border-left: 1px solid white;
}


.home-section-two img {
    max-width: 100%;
    height: auto;
   }

.home-section-three {
  
  position:relative;
  margin: 5%;
}

.home-section-three h3 {
    width: 80%;
    top:50%;
    left: 10%;
    font-weight: bold;
    margin:auto;
    color:var(--pink);
  }

.learnMore {
  background-color:var(--pink);
  width:250px;
  margin: auto ;
  padding: 15px 15px;
  margin-top:50px;
  margin-bottom: 50px;
}

.learnMore a {
    color: white;
    text-decoration: none;
    margin: auto;
  }

/* .beginYourJourneyBtn {
  width: 400px;
  background-color: white;  
  margin-bottom: 20px 10px;
  & a {
    color: var(--pink);
    text-decoration: none;
  }
} */

.home-section-four {
  background-color: var(--pink);
}

.home-section-four div{
    padding:2%;
    color: white;
  }

.home-section-four div h3 {
      font-size: 40px;
    }

.home-section-four p {
    width: 400px;
    margin: auto;
    margin-top: 25px;
  }

.home-section-four .beginJourneyFlex {
    display: flex;
    justify-content: space-between;
  }

.home-section-four .beginJourneyFlex span {
      position: relative;
      width: 100%;
    }

/* height: 400px; */

.home-section-four .beginJourneyFlex span img {
        position: relative;
        width: 70%;
        height: 100%;
        object: cover;
        top: 0;
        left: 0;
      }

.home-section-four .beginJourneyFlex .consultationUL {
     padding-left:15%;
     text-align: left;
     margin: auto;
    }

.home-section-four .beginJourneyFlex .consultationUL p {
      margin: 0;
     }

.beginJourneyBtn {
  width: 30%;
  background-color:white;
  padding: 1% !important;
}

.beginJourneyBtn a {
    color: var(--pink);
    text-decoration: none;
  }

.beginJourneyBtn {
  margin: auto;
  margin-top:45px}

.home-section-five h3 {
    /* font-weight: lighter; */
    font-size:40px;
    margin: 45px 0;
    color: var(--pink);
  }

.home-section-five .journeyBoxContainer {
    display: flex;
    width: 100%;
    padding: 15px;
    justify-content: space-around;
  }

.home-section-five .journeyBoxContainer .journeyBox {
      color:var(--pink) !important;
      width: 25%;
      padding: 25px;
    }

.home-section-five .journeyBoxContainer .journeyBox img{
        width: 80%;
        height: auto;
        border-radius:5px;
        margin-bottom:25px;
      }

.intro-box {
  width:80vw;
  margin:auto;
  display: flex;
}

#khprofile {
  width: 72vw;
  height: 100%;
  margin-left: 20%;
  margin-top: 3%;
  max-width: 500px;
}
#khul {
  background-color:white;
  width: 25vw;
  font-size: 1vw;
  margin-top: 7%;
  margin-left: -7%;
  letter-spacing: 2px;
  font-weight: lighter;
  max-height: 900px;
  max-width: 900px;
  /* min-width: 200px; */
  overflow: auto;

}

#khul p {
  margin: 0;
}

.footer {
  width: 100%;
  border-top: 1px solid white;
  background-color:var(--pink);
  color: white;
  padding: 15px 15px;
}
.scheduleCallBox {
  width:50%;
  background-color: white;
  margin: auto;
  padding:15px;
  max-width:800px;
  margin-bottom:25px;
}
.bottomNav {
  border-top:1px solid white;
  border-bottom:1px solid white;
  margin: auto;
  padding: 2% 0 2% 0;
  max-width:1200px;

}

.bottomContact {
  display: flex;
  justify-content: space-around;
  
  /* margin-top:15px !important;
  & p {
    text-align:right;
    margin: 0;
  }
  max-width:1200px;
  margin:auto; */
}

.typeButton {
  margin: 15px 15px;
  border: 0;
  background-color: #b78b20;
  color:white;
  font-weight: bold;
  width: 150px;
  border-radius: 15px;
  font-size: 15px;
}


.description {
  color:#b78b20;
  font-size: 18px;
  text-align: left;
}

.liMaker::marker{
  font-weight: bold;
}

.liMaker {
  list-style:upper-roman;
}

#home-section-one p:hover {
  transform:scale(1.1)
} 

.schedulecall {
  transform:scale(1.1)
}



.about-section-one ul {
  /* list-style-type: none; */
  text-align: left;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 1px;
  padding: 0px 30px 
}

#about-section-two {
  width: 100%;
}
.teamTwoContainer {
  justify-content:space-between;

}
.teamTwo {
  width: 30%;
  color: var(--pink);
  background-color:white;
  font-weight: bold;
  box-shadow: 5px 5px 20px -2px rgba(0,0,0,0.64);
  -webkit-box-shadow: 5px 5px 20px -2px rgba(0,0,0,0.64);
  -moz-box-shadow: 5px 5px 20px -2px rgba(0,0,0,0.64);
  
}
.teamTwo img {
    padding: 15px 15px;
  }
.teamTwo ul {
    list-style-type:none;
  }
.teamTwo li {padding: 15px 5px}
.teamTwo p{
    margin:15px 0;
    
  }

.khintro {
  display: flex;
}
.khintro img {
  width: 400px;
  /* padding: 15px; */
}
/* .teamContainer {
  background: linear-gradient(180deg,#c49090 -30%, #fefefe 20%);
    background: -webkit-linear-gradient(180deg,#c49090 0%, #fefefe 100%);
    background: -moz-linear-gradient(180deg,#c49090 0%, #fefefe 100%);
} */
.team {
  /* text-align: left !important; */
  width: 70%;
  margin: auto;
}
/* background: #c49090; */
.team .team-left{
    width: 35%;
  }
.team .team-left img {
      width: 85%;
      box-shadow: 1px 11px 24px -1px rgba(0,0,0,0.41);
      -webkit-box-shadow: 1px 11px 24px -1px rgba(0,0,0,0.41);
      -moz-box-shadow: 1px 11px 24px -1px rgba(0,0,0,0.41);
    }
.team .team-right{
    width: 65%;
  };
.team li {
    font-size: 14px;
    font-weight: normal;  
    }
.team ul {
    margin: auto;
    display: flex;
    flex-direction: column;    
    justify-content: center !important;
    width: 80%;
    /* background: #c49090; */
    background: linear-gradient(180deg,#d15858 0%, #962929 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
.team ul li {
      font-size: 20px;
      font-weight: normal;
    }

.meetTeam {
  text-align: right;
  font-size:30px; 
  text-decoration:underline;
  padding: 15px 55px
}

#teamQuote {
  width: 70%;
  text-align: center;
  margin: auto;
  padding: 50px 50px;
  /* color: var(--pink) */
}


.package-section-one .title {margin: 55px auto; color:var(--pink); }


.package-section-one .vipConsuting h4 {
      margin: 5%;color:var(--pink);
    }


.package-section-one .vipConsuting {
    border-top: 1px solid var(--pink);;
    border-bottom: 1px solid var(--pink);
    width: 80%;
    margin:auto;
    margin-bottom: 5%}


.package-section-one .vipConsuting h3 {
      margin-bottom: 5%;
    }


.package-section-one .vipConsuting h3 a {
        color: white;
        text-decoration: none;
      }

.package-section-two {
  display: flex;
  padding: 25px;
  margin: auto !important; 
  width: 100%;
}

.package-section-two .packageCard {
    width: 33.333%;
    margin: 0 25px;
    /* display: flex;
    flex-direction: column; */
    /* height: 1000px !important; */
    /* color:#d15858; */
    color:white;
    /* text-shadow: 1px 1px 8px white; */
    padding: 15px 15px ;
    /* box-shadow: 5px 4px 7px -4px rgba(0, 0, 0, 0.50); */
    box-shadow: 5px 5px 20px -2px rgba(0,0,0,0.64);
    -webkit-box-shadow: 5px 5px 20px -2px rgba(0,0,0,0.64);
    -moz-box-shadow: 5px 5px 20px -2px rgba(0,0,0,0.64);
    background: #c49090;
    /* background: linear-gradient(180deg,#c49090 -80%, #fefefe 100%);
    background: -webkit-linear-gradient(180deg,#c49090 0%, #fefefe 100%);
    background: -moz-linear-gradient(180deg,#c49090 0%, #fefefe 100%); */
      justify-content: space-between !important;
  }

.package-section-two .packageCard ul {
      max-width: 800px;
      height: 300px;
    }

.package-section-two .packageCard p {
      text-decoration: underline
    }

.package-section-two .packageCard li {
      text-align: left;
    }



.caseStudyItemContainer {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  color:var(--pink);
  background-color: white;
  min-height: 550px;
  padding: 25px;
  margin: 45px auto;
  
  max-width: 480px;
}



.caseStudyItemContainer .caseStudyItem-section-one {
    display: flex;
    justify-content: space-between;
    text-align:right;
  }



.caseStudyItemContainer .caseStudyItem-section-two {
    padding: 15px 15px;
    text-align: left;
  }

.caseStudy3DItem {
  position: relative;
  
}

.caseStudy3DItem img {
  position:relative;
  height: 550px;
  width: 400px;
}

.caseStudy3DItem .overImage {
  position:absolute;
  top:0px;
}

.vip-page {
  margin: 50px 45px;
  height: 400px;
  display: flex; 
  text-align: left;
  
}

.vip-page h3 {
    margin: 25px auto;
  }

.vip-page h3 {
      margin:25px 
    }

.vip-page .vipContainer-left {
      width: 65%;
    }

.vip-page .vipContainer-right {
      width: 35%;
      border-left: 1px solid black;
      padding-left: 50px;
    }

.contact-section {
  text-align: left;
  margin: 110px 50px;
}

.vipInquireButton {
  background:var(--pink);
  color:white;
  border:none;
  height: 80px;
  width:350px;
  margin:1.5%;
  transition: 0.2s;
}

.vipInquireButton h4{
    color: white!important
  }
.buttons {
  background: white;
  color:var(--pink);
  border: none;
  width: 250px;
  height: 50px;
  margin: 1.5%;
  transition: 0.2s;
  /* box-shadow: -1px -1px 20px 0px rgba(0,0,0,0.15) inset;
-webkit-box-shadow: -1px -1px 20px 0px rgba(0,0,0,0.15) inset;
-moz-box-shadow: -1px -1px 20px 0px rgba(0,0,0,0.15) inset; */
}

.vipInquireButton:hover {
  transform: scale(1.05);
  background:rgb(209, 88, 88);
}
.buttons:hover {
  background:rgb(209, 88, 88);
  transform: scale(1.05)
}

.popup {
  transition: opacity 0.7s ease;
}

.fadeIn {
  animation: fadeInAnimation 1s linear;
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.teamOne {
  margin: 25px 0
}



@media only screen and (max-width: 1024px) {
  html,
  body{
    width:100%;
    overflow-x:hidden;
  }
 
  .links {
    text-align: left;
    line-height: 35px;
  }  
  #packages {
    display:block !important;
    width: 80vw;
    height:100%
  }

  .package {
    margin:auto !important;
    width: 350px !important;
  }

  #home-section-one p {
    border-radius:5px;
  }
/* 
  #home-section-two{
    padding-right:0
  }    */

  #home-section-three{
    height: 80vh;
  } 

  #khprofile {
    width: 90vw;
    height: 100%;
    margin-left:0;
    margin-top: 0;
  }

  #intro-box {
    display: block;
  }

  #khul {
    background-color:white;
    width: 200%;
    padding: 5px;
    font-size: 2vw;
    margin-top: 1%;
    margin-left: -87%;
    letter-spacing: 1px;
  }

  .team {
      display: block !important;
      text-align: center;
      margin:auto;
      width: 100% !important;
  }

  .team ul {
    padding: 50px 0 50px 0;
    width: 100%;
  }
  .team img {
    width:100%;
  }

  .teamTwoContainer {
    display:block !important;
  }
    .teamTwoContainer .teamTwo {
      width: 95%;
      margin: auto !important;
    }

  .meetTeam {
    text-align: center !important;
    padding: 15px 15px !important;
    margin:0;
  }
  .teamOne {
    margin: 10px 10px;
    
  }

  .scheduleCallBox {
    width: 50%;
    padding: 10px 10px 10px 10px;
  }
    .scheduleCallBox h3 {
      font-size:10px;
      margin: 0;
    }
    .home-section-one h3 {
      padding-top:55px;
      margin: auto;
      text-align: center;
    }.home-section-one div {
      display: block;
      border: none;
      padding-bottom:25px;
    }
      .home-section-one div span {
        border: none;
      }
        .home-section-one div span h4 {
          display: none;
        }
  /* .home-section-two {
    display: none;
  } */

  .home-section-three{
    position: relative;
    margin :50px auto;
    color: var(--pink);
  }
    .home-section-three h3 {
      font-size:20px;
      top:20%;
    }

  .home-section-four {
    border: none;
    padding: 20px;
  }
    .home-section-four p{
      width: 90%;
    }
    .home-section-four div {
      border: none;
    }
    .home-section-four h3 {
      padding: 25px;
    }
   .home-section-four .beginJourneyFlex {
    width: 100%;
    border: none;
    }
    .home-section-four .beginJourneyFlex .consultationUL {
      padding: 25px;
      border-top: 1px solid white;
      margin-top: 25px !important;
      }
  .beginJourneyBtn {
    width: 40%
  }
  .beginJourneyImgBlock{
    display: none;
  }
  .journeyBoxContainer {
    display: block !important;
  }
    .journeyBoxContainer .journeyBox  {
      width: 100% !important ;
    }
      .journeyBoxContainer .journeyBox img {
        width: 100% !important;
      }
      .journeyBoxContainer .journeyBox p {
        text-align:center !important;
      }
      .package-section-one .vipConsuting h3 a {
        font-size: 15px;
        padding: 10px 10px
      }
    .package-section-one .vipConsuting {
    padding-bottom: 15px}

  
  .package-section-two {
    padding: 0;
    display: block;
  }

    .package-section-two .packageCard {
      width: 90% !important;
      margin: 25px auto;
      padding: 25px;
    }
    .package-section-two ul {
      height: auto !important;
    }
    .team .meetTeam {
      border-bottom: none !important;
    }
    .team .team-left {
      width: 90%;
      margin: auto;
      border-right: none !important;
    }
      .team .team-left img {
        width: 100%;
        border-radius: 0;
      }
    .team .team-right {
      width: 100%;
    }
      .team .team-right li {
        padding: 5px 30px;
        font-size:14px;
      }

  .teamsTwo {
    border: 1px solid black;
  }

  #teamQuote {
    padding: 10px 0;
    margin-bottom: 25px;
    font-size: 18px;
  }

  .vip-page {
    display: block;
    height: auto;
  }
    .vip-page h3 {
      font-size:20px;
      text-align:center !important;
      font-weight: bold;
    }
    .vip-page .vipContainer-left{
      width: 100%;
    }
    .vip-page .vipContainer-right{
      width: 100%;
      font-weight: bold;
      border: none;
      border-bottom: 1px solid black;
      font-size: 10px;
      padding: 0;
    }

  .caseStudyItemContainer {
    width: 90%;
    height: 800px;
  }
  .footer {
    display:block !important;
  }
      .footer .bottomContact h5 {
        font-size: 15px !important;
      }
    .footer h5 {
      font-size: 15px !important;
      font-weight: lighter
    }
  .bottomContact {
    display:block;
  }

  #home-section-one-right {
    border-left: none;
  }

  .buttons {
    width: 50%;
    margin-bottom:25px;
  }

  .vipInquireButton {
    width: 80%;
    height:80%;
    margin: 25px 0
  }

  .contact-section {
    margin: 70px 25px;
  }

  .vip-page {
    margin: 70px 30px;
  }

  .vipContainer-right {
    padding-bottom : 25px !important;
  }
    .vipContainer-right p {
      margin-bottom:0
    }

  /* .caseStudyItem-section-two {
    padding: 0 !important;
  } */
 .toggleLanguage {
  float:left;
  margin-left:15px;
 }

 .caseStudy3DItem {
  height: 400px;
  width:auto;
 }
}
